
export namespace UserActions {
    export class Load {
        static readonly type = '[User] Load';
    }
}

export namespace NotificationActions {
  export class Load {
    static readonly type = '[Notification] Load'
  }
}
